import React from 'react'
import { data } from './data'
import './PassPage.scss'

const Pass = () => {

  return (
    <div className='pass-container bg-image space-y-12 items-center flex flex-col justify-center pb-24'>
      <div className='flex flex-col lg:flex-row pt-12 pb-12 lg:pb-0 xl:pb-12 container xl:px-12'>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-10 w-full xl:px-12'>
          <div className='grid-item flex justify-center'>
            <img src='/pass_page/card_hero.png' alt='card image' className='w-full sm:w-3/4 md:w-2/3 lg:w-full' />
          </div>
          <div className='grid-item'>
            <div className='gap-6 flex flex-col justify-center items-center h-full'>
              <a href='https://magiceden.io/marketplace/mckennas_heroes_and_villains_pass' target='_blank' rel='noreferrer' className='w-full sm:w-3/4 md:w-2/3 lg:w-full'>
                <button className='uppercase italic w-full text-white font-bold font-montserrat-italic border-white border-4 rounded-xl bg-black px-8 py-2 text-2xl lg:text-base xl:text-2xl'>
                  Buy Pass (Magic Eden)
                </button>
              </a>
              <a href='https://solanart.io/collections/heroesandvillainspasses' target='_blank' rel='noreferrer' className='w-full sm:w-3/4 md:w-2/3 lg:w-full'>
                <button className='uppercase italic w-full text-white font-bold font-montserrat-italic border-white border-4 rounded-xl bg-black px-8 py-2 text-2xl lg:text-base xl:text-2xl'>
                  Buy Pass (Solanart)
                </button>
              </a>
              <a href='https://opensea.io/collection/heroes-villains-passes' target='_blank' rel='noreferrer' className='w-full sm:w-3/4 md:w-2/3 lg:w-full'>
                <button className='uppercase italic w-full text-white font-bold font-montserrat-italic border-white border-4 rounded-xl bg-black px-8 py-2 text-2xl lg:text-base xl:text-2xl'>
                  Buy Pass (OpenSea)
                </button>
              </a>
            </div>
          </div>
          <div className='grid-item flex justify-center'>
            <img src='/pass_page/card_villain.png' alt='card image' className='w-full sm:w-3/4 md:w-2/3 lg:w-full'/>
          </div>
        </div>
      </div>
      <div className='container italic uppercase xl:px-12'>
        <div className='xl:px-12'>
          {data.map((item, idx) => (
            <>
              <button key={idx} className='row-yellow w-full font-poppins-italic uppercase italic text-2xl font-bold text-black py-2 mt-4'>
                {item.title}
              </button>
              {item.items.map((subItem, index) => (
                <button key={index} className={`w-full uppercase font-poppins-italic italic text-2xl font-bold text-white py-2 ${index % 2 === 0 ? 'row-blue' : 'row-blue'}`}>
                  {subItem.title}
                </button>
              ))}
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Pass
