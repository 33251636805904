export const data = [
  {
    title: "Ongoing Benefits",
    items: [
      {
        title: "10% Discount on \"Origins\" minting",
      },
      {
        title: "2X staking bonus",
      },
      {
        title: "Private discord channel",
      }
    ]
  },
  {
    title: "Upcoming Benefits",
    items: [
      {
        title: "(2) Free Sidekick (HP) or Henchmen (VP) mints",
      },
      {
        title: "Extreme Measures p2E: early access",
      },
      {
        title: "Extreme Measures p2E: exclusive items",
      },
      {
        title: "10% DIscount on \"Sidekicks & Henchmen\" minting",
      },
      {
        title: "10% DIscount on \"Mutants\" minting",
      },
      {
        title: "10% DIscount on \"Alliances\" minting",
      }
    ]
  },
  {
    title: "Past Benefits",
    items: [
      {
        title: "(2) Free \"Origins\" mints",
      },
      {
        title: "Origins Sourcebook #0: Passholder Variant cover",
      }
    ]
  }
]
