import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Collection from "@components/partials/PassPage"

const PassPage = () => (
  <Layout
    title="Mark McKenna's Heroes & Villains NFT"
    contentClassName="PassPageContent px-0"
    type="pass"
  >
    <Collection/>
  </Layout>
)

export default PassPage
